import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import PageLayout from "../layouts/PageLayout/PageLayout";
import ScrollingNavLayout from "../layouts/ScrollingNavLayout/ScrollingNavLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import { SanityDybGenericPage } from "../model/dyb";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query DybGenericPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityDybGenericPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      language
      slug {
        current
      }
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      _rawContent(resolveReferences: { maxDepth: 8 })
      translationSponsors {
        ...TranslationSponsors
      }
      licence {
        ...Licence
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "doYourBit" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const DybGenericPage = ({
  pageContext,
  data: { menus, page, campaigns },
  location
}: DybGenericPageProps) => {
  const { title, _rawContent } = page;
  return (
    <PageLayout
      siteArea={SiteArea.DO_YOUR_BIT}
      metadata={{
        page,
        title,
        alternates: pageContext.alternates
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <ScrollingNavLayout
        campaigns={campaigns.nodes}
        title={title}
        content={_rawContent}
        social={true}
      />
    </PageLayout>
  );
};

export default DybGenericPage;

interface DybGenericPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityDybGenericPage;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
